/*!
 * MYA
 */

// Bootstrap
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";

// App variables & defaults
@import "app/variables";
@import "app/animate";
@import "app/print";

// Utils
@import "app/utils/utils";

// Default Layout
@import "app/layout/layout";
@import "app/layout/layout-extra";
@import "app/layout/layout-animation";
@import "app/layout/top-navbar";
@import "app/layout/sidebar";
@import "app/layout/offsidebar";
@import "app/layout/user-block";
@import "app/layout/settings";

// Bootstrap changes/extras
@import "app/bootstrap/bootstrap-reset";
@import "app/bootstrap/bootstrap-custom";
@import "app/bootstrap/button-extra";
@import "app/bootstrap/dropdown-extra";
@import "app/bootstrap/pagination-extra";

// Elements
@import "app/elements/typo";

// Containers
@import "app/containers/mailbox";
@import "app/containers/profile";
@import "app/containers/agenda-type";
@import "app/containers/pages";

// Components
@import "app/components/page-loader";
@import "app/components/placeholder";
@import "app/components/cards";
@import "app/components/circles";
@import "app/components/half-float";
@import "app/components/slim-scroll";
@import "app/components/inputs";
@import "app/components/flashmessage";
@import "app/components/heading";
@import "app/components/calendar-sidebar";
@import "app/components/form";
@import "app/components/spinner";
@import "app/components/popover";
@import "app/components/easypie-chart";
@import "app/components/link";
@import "app/components/button";
//to move!

@import "app/fullcalendar/fullcalendar";
@import "app/fullcalendar/fullcalendar-custom";
@import "app/fullcalendar/fullcalendar-event";
