.heading {
    display: flex;
    align-items: center;
    margin: -$content-padding;
    margin-bottom: 20px;
    padding: $content-padding /2 $content-padding;
    font-weight: normal;
    background-color: $content-heading-bg;

    min-height: 54px;

    &.heading-nomargin {
        margin-bottom: 0;
    }
}

.heading-half {
    margin: -15px -15px 20px -30px;
}

.heading-start {

}

.heading-end {
    margin-left: auto;

    > * {
        float: left;
    }
}

.heading-title {
    line-height: $headings-line-height;
    margin-bottom: 0;
    font-size: 18px;
    color: $subsidiary;
    font-weight: 700;
}

.heading-subtitle {
    font-size: 15px;
    color: $subsidiary-lighter;
    font-weight: 600;
    margin-bottom: 0;
}
