.link-light {
    color: $white;

    &:hover, &:focus {
        color: $primary-lightest;
    }
}

.text-link { 
    cursor:pointer;
    text-decoration:underline;
}